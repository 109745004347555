// pages/ContactMe.js - Complete contact form implementation
import React, { useState } from 'react'
import './ContactMe.css'

const ContactMe = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  
  const [formStatus, setFormStatus] = useState({
    submitted: false,
    error: false
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would normally send the form data to your backend or a service like Formspree
    console.log('Form submitted:', formData);
    
    // For demonstration, simulate successful submission
    setFormStatus({
      submitted: true,
      error: false
    });
    
    // Reset form
    setFormData({
      name: '',
      email: '',
      message: ''
    });
  };

  return (
    <div className="contact-page container py-5">
      <h1 className="display-5 fw-bold text-center mb-5">Let's Create Together</h1>
      <p className="text-center mb-5">Feel free to get in touch with me using the methods below:</p>
      
      <div className="row justify-content-center">
      <div className="col-lg-8">
      <div className="glassmorphism p-5 rounded-4">
      {formStatus.submitted ? (
              <div className="alert alert-success">
                Thank you for your message! I'll get back to you soon.
              </div>
            ) : (
              <form onSubmit={handleSubmit}>
              <div className="mb-4">
              <label htmlFor="name" className="form-label">Name</label>
                  <input 
                    type="text" 
                    className="form-control form-control-lg border-0 bg-light"
                    id="name" 
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">Email</label>
                  <input 
                    type="email" 
                    className="form-control form-control-lg border-0 bg-light"
                    id="email" 
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="message" className="form-label">Message</label>
                  <textarea 
                    className="form-control form-control-lg border-0 bg-light"
                    id="message" 
                    name="message"
                    rows="5"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>
                <button type="submit" className="btn btn-primary btn-lg w-100 rounded-pill py-3">Submit</button>
              </form>
            )}
          </div>
        </div>
        
        {/* <div className="col-md-6">
          <div className="contact-info card p-4">
            <h3>Other Ways to Connect</h3>
            <p className="mb-3">Email: <a href="mailto:createdigi@outlook.com">createdigi@outlook.com</a></p>
            
            <div className="social-media-links mb-4">
              <h4>Social Media</h4>
              <div className="d-flex gap-3 fs-3">
                <a href="#" className="text-dark"><FaGithub /></a>
                <a href="#" className="text-dark"><FaLinkedin /></a>
                <a href="#" className="text-dark"><FaTwitter /></a>
                <a href="#" className="text-dark"><FaYoutube /></a>
              </div>
            </div>
            
            <div className="business-hours">
              <h4>Business Hours</h4>
              <p>Monday - Friday: 9AM - 5PM</p>
              <p>Weekends: By appointment only</p>
            </div>
          </div>
        </div> */}
        
      </div>
    </div>
  )
}

export default ContactMe