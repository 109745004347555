import React from 'react'
import "./Home.css";
import { motion } from 'framer-motion';
import { 
  FaRobot, 
  FaGamepad, 
  FaCode, 
  FaServer, 
  FaMobileAlt,
  FaEthereum,
  FaLock,
  FaDatabase
} from 'react-icons/fa';

const Home = () => {
  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <main className="py-5">
      <section className="container py-5">
        <div className="row align-items-center g-5">
          {/* Left Column */}
          <motion.div 
            className="col-lg-6"
            initial="hidden"
            animate="visible"
            variants={fadeIn}
            transition={{ delay: 0.2 }}
          >
            <div className="pe-lg-4">
              <h2 className="display-4 fw-bold mb-4">
                Crafting <span className="text-gradient">Digital Futures</span>
              </h2>

              <div className="specializations mb-4">
                <div className="d-flex flex-wrap gap-2">
                  {['Full-Stack Architect', 'AI Developer', 'Game Engineer', 'Tech Innovator'].map((title, index) => (
                    <motion.span
                      key={index}
                      className="profession-badge gradient-badge"
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: 'spring', stiffness: 300 }}
                    >
                      {title}
                    </motion.span>
                  ))}
                </div>
              </div>

              <p className="lead text-muted mb-4 fs-5">
              Full-stack developer specializing in modern web applications and mobile experiences. Focused on creating intuitive, performant solutions, Building intelligent systems and immersive experiences through cutting-edge technology.
              </p>

              <div className="expertise-grid mb-5">
  {[
    { 
      icon: <FaRobot />, 
      title: 'AI/ML', 
      desc: 'Neural Networks & Deep Learning' 
    },
    { 
      icon: <FaGamepad />, 
      title: 'Game Dev', 
      desc: 'Unity/Unreal Engine & 3D Modeling' 
    },
    { 
      icon: <FaCode />, 
      title: 'Web Apps', 
      desc: 'React/Node.js Full-Stack Solutions' 
    },
    { 
      icon: <FaServer />, 
      title: 'Cloud Systems', 
      desc: 'AWS/GCP & Microservices' 
    },
    { 
      icon: <FaMobileAlt />, 
      title: 'Mobile Apps', 
      desc: 'iOS/Android & Cross-Platform' 
    },
    { 
      icon: <FaEthereum />, 
      title: 'Blockchain', 
      desc: 'Smart Contracts & DApps' 
    },
    
    { 
      icon: <FaLock />, 
      title: 'Cybersecurity', 
      desc: 'Security Protocols & Encryption' 
    },
    { 
      icon: <FaDatabase />, 
      title: 'Big Data', 
      desc: 'Data Analytics & Processing' 
    }
  ].map((item, index) => (
    <motion.div 
      key={index}
      className="expertise-card glassmorphism"
      whileHover={{ y: -5 }}
      transition={{ duration: 0.3 }}
    >
      <div className="text-primary mb-2" style={{ fontSize: '2rem' }}>
        {item.icon}
      </div>
      <h5 className="mb-1">{item.title}</h5>
      <small className="text-muted">{item.desc}</small>
    </motion.div>
  ))}
</div>

              <div className="stats-grid">
                <motion.div 
                  className="stat-box"
                  whileHover={{ scale: 1.05 }}
                  transition={{ type: 'spring' }}
                >
                  <h3 className="text-gradient mb-0">5+</h3>
                  <p className="text-muted mb-0">Years Experience</p>
                </motion.div>
                <motion.div 
                  className="stat-box"
                  whileHover={{ scale: 1.05 }}
                  transition={{ type: 'spring' }}
                >
                  <h3 className="text-gradient mb-0">50+</h3>
                  <p className="text-muted mb-0">Projects Developed</p>
                </motion.div>

              </div>
            </div>
          </motion.div>

          {/* Right Column */}
          <motion.div 
            className="col-lg-6"
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.4 }}
          >
            <div className="video-container glassmorphism p-3 rounded-4">
              <div className="ratio ratio-16x9">
                <iframe
                  className="rounded-3"
                  src="https://www.youtube.com/embed/wKjGMgWhqys?si=HZduK1P2fAHaJPAe"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              <div className="video-overlay"></div>
            </div>
          </motion.div>
        </div>
      </section>
    </main>
  )
}

export default Home