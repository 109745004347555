// App.js - Corrected Routing
import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ContactMe from "./pages/ContactMe";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "./components/Navbar";
import Header from "./components/Header";
import { FaGithub } from 'react-icons/fa';
import { SpeedInsights } from '@vercel/speed-insights/react';



function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <header>
          <Navbar />
          <Header />
        </header>
        <main className="main-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/contact-me" element={<ContactMe />} />
          </Routes>
        </main>

        <SpeedInsights />

      

        <footer className="footer glassmorphism">
          <div className="container py-4">
            <div className="d-flex justify-content-between align-items-center">
              <p className="mb-0 text-muted">
                © {new Date().getFullYear()} Bo Shayea · Crafting Digital Excellence
              </p>
              <div className="d-flex gap-3">
                <a href="https://github.com/" className="text-dark hover-primary">
                  <FaGithub size={24} />
                </a>
                {/* Other social links */}
              </div>
            </div>
          </div>
        </footer>

      </BrowserRouter>
    </div>
  );
}

export default App;