import React from 'react'
import logo from "../logo.png";

const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-lg fixed-top glassmorphism py-3">
      <div className="container">
        <a className="navbar-brand" href="/">
          <img 
            className="logo rotate-animation" 
            src={logo} 
            alt="Bo Shayea"
            style={{ width: '50px', height: '50px' }}
          />
        </a>
        <button
  className="navbar-toggler"
  type="button"
  data-bs-toggle="collapse"
  data-bs-target="#navbarSupportedContent"
  aria-controls="navbarSupportedContent"
  aria-expanded="false"
  aria-label="Toggle navigation"
>
  <span className="navbar-toggler-icon"></span>
</button>
<div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item mx-2">
              <a className="nav-link underline-animation" href="/">Home</a>
            </li>
            <li className="nav-item mx-2">
              <a className="nav-link underline-animation" href="/privacy-policy">Privacy</a>
            </li>
            <li className="nav-item mx-2">
              <a className="nav-link underline-animation" href="/contact-me">Contact</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Navbar