import React from 'react'
import "./PrivacyPolicy.css"
import { motion } from 'framer-motion'

const PrivacyPolicy = () => {
  const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 }
  }

  return (
    <motion.div 
      initial="initial"
      animate="animate"
      variants={fadeIn}
      className="privacy-policy glassmorphism"
    >
      <h1>Privacy Policy</h1>
      <p className="lead-text">
        Your privacy is important to us. This Privacy Policy explains how we collect, 
        use, disclose, and safeguard your personal information.
      </p>

      <h2>Information We Collect</h2>
      <p>
        We may collect information that you voluntarily provide to us, including:
      </p>
      <ul>
        <li>Contact details (name, email address)</li>
        <li>Professional information (company, job title)</li>
        <li>Project-related information</li>
        <li>Communication preferences</li>
      </ul>

      <h2>How We Use Your Information</h2>
      <p>
        Your information helps us deliver exceptional services:
      </p>
      <ul>
        <li>Develop and improve our services</li>
        <li>Respond to inquiries and provide support</li>
        <li>Send relevant updates and resources</li>
        <li>Analyze service usage patterns</li>
      </ul>

      <h2>Data Protection</h2>
      <p>
        We implement industry-standard security measures including:
      </p>
      <ul>
        <li>SSL/TLS encryption for data transmission</li>
        <li>Regular security audits</li>
        <li>Limited access controls</li>
        <li>Secure cloud storage solutions</li>
      </ul>

      <h2>Contact Us</h2>
      <p>
        For privacy-related inquiries, reach us at:<br />
        <a href="mailto:createdigi@hotmail.com" className="email-link">
          createdigi@hotmail.com
        </a>
      </p>
    </motion.div>
  )
}

export default PrivacyPolicy