// components/Header.js - Updated with actual content
import React from 'react'

const Header = () => {
  return (
    <section className="header-wrapper position-relative overflow-hidden">
      <div className="animated-grid-bg">
        <div className="container min-vh-100 d-flex align-items-center">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center position-relative">
              <h1 className="display-3 fw-bold mb-4">
                <span className="text-gradient">Bo Shayea</span>
              </h1>
              <p className="lead text-muted mb-4 fs-4">
                Transforming Ideas into Digital Reality
              </p>
              {/* <div className="d-flex gap-3 justify-content-center">
                <a href="#contact" className="btn btn-primary btn-lg px-5 rounded-pill glow-on-hover">
                  Let's Connect
                </a>
                <a href="#projects" className="btn btn-outline-dark btn-lg px-5 rounded-pill hover-scale">
                  My Work
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Header